import React from 'react';
import '../styles/koncerti.css';
import SwipeGallery from '../pages/SwipeGallery';

const Vesture = () => {

    const vesturesBildes = [
        "/vesture/0a170d50-7f58-4bdf-956c-1440d4f38526.jpg",
        "/vesture/1a598ff4-2ba5-42a4-b06e-c94f343a3eff.jpeg",
        "/vesture/1faf4606-ff77-472e-80fc-eeed803bdb9d.jpg",
        "/vesture/2AF37B04-155A-466D-971B-6DC8B65F60CF_1_105_c.jpeg",
        "/vesture/4b97bd61-ac78-4a60-881d-041dc5df24a6.jpg",
        "/vesture/6A111D64-8607-4DA1-AFCC-6CD5032AD05E_1_105_c.jpeg",
        "/vesture/6B7E8AFA-3BC6-469B-A36D-202A32EE01CE_1_105_c.jpeg",
        "/vesture/98191662-3ba7-4911-bca0-d54fa73de242.jpeg",
        "/vesture/9CCABC7B-3FAC-4674-A1FF-653D784F67F4_1_105_c copy.jpeg",
        "/vesture/e63ec963-4c32-40d4-8d7d-3dde4c1531ce.jpeg",
        "/vesture/IMAG1124.jpg"
      ]
      

    return (
        <div className='koncerti'>
            <h1>VĒSTURE</h1>
            <p>
                &emsp; 1969. gadā kolektīvu dibināja Laimonis Zvejnieks, kas bija arī pirmais kolektīva vadītājs. Taču cauri laikiem kopā ir bijuši 11 vadītāji. Pēdējais ir mūsu mīļais Mārtiņš Pēdājs.
                <br></br>
                &emsp; Kopā kolektīvs ir piedalījies pēdējos 10 deju svētkos.
                <br></br>
                &emsp; Nosaukumu ŪSIŅŠ Ļeņina padomju saimniecības jauniešu deju kolektīvs pieņēma 1990.gada novembrī vēlēšanu rezultātā. Un šī nosaukuma iniciators bija neviens cits kā bijušais mērs Raimonds Čudars.
                <br></br>
                &emsp; Šeit arī pāris vēsturiski foto.
            
            </p>
            <SwipeGallery images={vesturesBildes} />

        </div>
    );
};

export default Vesture;
import React from 'react';
import '../styles/Logo.css';

function Logo({ onClick, additionalClass }) {
  return (
    <div className={`logo ${additionalClass}`} onClick={onClick}>
    </div>
  );
}

export default Logo;

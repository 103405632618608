import React from 'react';
import '../styles/Par.css';

const Par = () => {

    return (
        <div className='par'>
            <h1>PAR MUMS</h1>
            <div className='Ievads'>
                <img src='par/usins-167.jpg' alt='mes ziema'>
                </img>
                <p>
                    &emsp; Mēs esam latviešu tautasdeju kolektīvs "Ūsiņš". Visiem 46 mūsu dalībniekiem (ieskaitot vadītājus) deja ir patiesa sirdslieta.
                    <br></br>
                    &emsp; Mūsu mājvieta ir Salaspils kultūras nams "Rīgava", kurā regulāri nākam uz mēģinājumiem jau 55 gadus!

                </p>

            </div>
        </div>
    );
};

export default Par;
import React, { useState } from 'react';
import { useSwipeable } from 'react-swipeable';
import '../styles/SwipeGallery.css';

const SwipeGallery = ({ images }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleSwipedLeft = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const handleSwipedRight = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
  };

  const swipeHandlers = useSwipeable({
    onSwipedLeft: handleSwipedLeft,
    onSwipedRight: handleSwipedRight,
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  return (
    <div className="swipe-gallery" {...swipeHandlers}>
      <img src={images[currentIndex]} alt={`Gallery ${currentIndex}`} className="gallery-image" />
      <div className="gallery-controls">
        <button onClick={handleSwipedRight}>&lt;</button>
        <button onClick={handleSwipedLeft}>&gt;</button>
      </div>
    </div>
  );
};

export default SwipeGallery;

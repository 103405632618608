import React from 'react';
import '../styles/SekoMums.css';

function SekoMums({ onBackClick }) {
  return (
    <div className="seko-mums-section">
      <h2>SEKO MUMS</h2>
      <p>Uzzini vairāk par mums, un tiekamies Septembra atlasēs ;)</p>
      <div className="social-icons">
        <a href="https://www.facebook.com/jdkUsins/" target="_blank" rel="noopener noreferrer">
          <img width="24" height="24" viewBox="0 0 24 24" fill="none" src='https://uxwing.com/wp-content/themes/uxwing/download/brands-and-social-media/facebook-app-round-white-icon.png' alt="Facebook" />
        </a>
        <a href="https://www.instagram.com/usins_jdk/" target="_blank" rel="noopener noreferrer">
          <img width="24" height="24" viewBox="0 0 24 24" fill="none" src='https://uxwing.com/wp-content/themes/uxwing/download/brands-and-social-media/instagram-white-icon.png' alt="Instagram" />
        </a>
      </div>
      <button onClick={onBackClick}>
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M15 18l-6-6 6-6" stroke="#E9E0D9" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
      </button>
    </div>
  );
}

export default SekoMums;

import React from 'react';

const Citi = () => {
    // Add your code here

    return (
        <div>
            {/* Add your modal content here */}
        </div>
    );
};

export default Citi;
import React, { useState, useEffect } from "react";
import Durvis from "./Durvis";
import Logo from "./Logo";
import Programma from './Programma';
import Mes from './Mes';
import SekoMums from './SekoMums';

export default function Home() {
  const [isOpen, setIsOpen] = useState(false);
  const [logoClass, setLogoClass] = useState('');
  const [showContent, setShowContent] = useState(false);
  const [activeSection, setActiveSection] = useState(''); // Manage which section is active
  const [touchStartX, setTouchStartX] = useState(0);
  const [touchStartY, setTouchStartY] = useState(0);
  const [touchEndX, setTouchEndX] = useState(0);
  const [touchEndY, setTouchEndY] = useState(0);
  const [touchStartTime, setTouchStartTime] = useState(0);
  const [activeModal, setActiveModal] = useState(null);


  const openDoors = () => {
    setLogoClass('twist');
    setShowContent(true);
    setActiveSection('');
    setTimeout(() => {
      setIsOpen(true);
      setTimeout(() => {
        setLogoClass('twist hide');
      }, 1900);
    }, 2000);

    setTouchStartX(0);
    setTouchStartY(0);
    setTouchEndX(0);
    setTouchEndY(0);
    setTouchStartTime(0);
    handleTouchEnd();

  };

  const handleMenuClick = (option) => {
    setActiveSection(option);
    setTouchStartTime(0);
    setTouchStartX(0);
    setTouchStartY(0);
    setTouchEndX(0);
    setTouchEndY(0);
    handleTouchEnd();
    
  };

  const handleBackClick = () => {
    setActiveSection('');
  };

  const handleTouchStart = (e) => {
    setTouchStartX(e.targetTouches[0].clientX);
    setTouchStartY(e.targetTouches[0].clientY);
    setTouchStartTime(Date.now());
  };

  const handleTouchMove = (e) => {
    setTouchEndX(e.targetTouches[0].clientX);
    setTouchEndY(e.targetTouches[0].clientY);
  };

  const handleTouchEnd = () => {
    const deltaX = touchEndX - touchStartX;
    const deltaY = touchEndY - touchStartY;
    const elapsedTime = Date.now() - touchStartTime;
    const minSwipeDistance = 75;
    const maxSwipeDuration = 250; // Adjust as needed

    if (activeModal !== null) {
      // Do not handle swipes if a modal is open
      setTouchStartX(0);
      setTouchStartY(0);
      setTouchEndX(0);
      setTouchEndY(0);
      return;
    }
    
    // Ensure the swipe gesture is significant
    if (elapsedTime < maxSwipeDuration && touchEndX !== 0 && touchEndY !== 0 && touchStartX !== 0 && touchStartY !== 0) {
      // Swipe left

      if (touchStartX - touchEndX > minSwipeDistance && Math.abs(deltaX) > Math.abs(deltaY)) {
        if (activeSection === "PROGRAMMA") {
          setActiveSection("");
          setTouchStartX(0);
          setTouchStartY(0);
          setTouchEndX(0);
          setTouchEndY(0);
        } else if (activeSection === "") {
          setActiveSection("SEKO");
          setTouchStartX(0);
          setTouchStartY(0);
          setTouchEndX(0);
          setTouchEndY(0);
        }
      }
      // Swipe right
      else if (touchStartX - touchEndX < -minSwipeDistance && Math.abs(deltaX) > Math.abs(deltaY)) {
        if (activeSection === "SEKO") {
          setActiveSection("");
          setTouchStartX(0);
          setTouchStartY(0);
          setTouchEndX(0);
          setTouchEndY(0);
        } else if (activeSection === "") {
          setActiveSection("PROGRAMMA");
          setTouchStartX(0);
          setTouchStartY(0);
          setTouchEndX(0);
          setTouchEndY(0);
        }
      }
      // Swipe up
      else if (touchStartY - touchEndY > minSwipeDistance && Math.abs(deltaY) > Math.abs(deltaX)) {
        if (activeSection === "") {
          setActiveSection("MĒS");
          setTouchStartX(0);
          setTouchStartY(0);
          setTouchEndX(0);
          setTouchEndY(0);
        }
      }
      // Swipe down
      else if (touchStartY - touchEndY < -minSwipeDistance && Math.abs(deltaY) > Math.abs(deltaX)) {
        if (activeSection === "MĒS") {
          setActiveSection("");
          setTouchStartX(0);
          setTouchStartY(0);
          setTouchEndX(0);
          setTouchEndY(0);
        }
      }
    }
    setTouchStartX(0);
    setTouchStartY(0);
    setTouchEndX(0);
    setTouchEndY(0);

  };

  useEffect(() => {
    window.addEventListener('touchstart', handleTouchStart);
    window.addEventListener('touchmove', handleTouchMove);

    // Cleanup listeners on component unmount
    return () => {
      window.removeEventListener('touchstart', handleTouchStart);
      window.removeEventListener('touchmove', handleTouchMove);
    };
  }, [activeModal]);

  return (
    <div className={`container ${activeSection ? `active-${activeSection}` : ''}`}
         onTouchStart={handleTouchStart}
         onTouchMove={handleTouchMove}
         onTouchEnd={handleTouchEnd}>
      <Durvis side="left" isOpen={isOpen}>
        <Logo additionalClass={logoClass} onClick={openDoors} />
      </Durvis>
      <Durvis side="right" isOpen={isOpen} />
      {showContent && (
        <div className="main-content">
          <img width='420' height='150' src="/virsraksts.png" alt="JDK ŪSIŅŠ" />
          <nav>
            <ul>
              <li onClick={() => handleMenuClick('PROGRAMMA')}>PROGRAMMA</li>
              <li onClick={() => handleMenuClick('MĒS')}>MĒS</li>
              <li onClick={() => handleMenuClick('SEKO')}>SEKO MUMS</li>
            </ul>
          </nav>
        </div>
      )}
      <div className="section PROGRAMMA" style={{ visibility: activeSection === 'PROGRAMMA' }}>
        <Programma onBackClick={handleBackClick} />
      </div>
      <div className="section MĒS" style={{ visibility: activeSection === 'MĒS' }}>
        <Mes onBackClick={handleBackClick} setActiveModal={setActiveModal} />
      </div>
      <div className="section SEKO" style={{ visibility: activeSection === 'SEKO' }}>
        <SekoMums onBackClick={handleBackClick} />
      </div>
    </div>
  );
}

import React from 'react';
import SwipeGallery from '../pages/SwipeGallery';
import '../styles/koncerti.css';

const Celojumi = () => {
    const celojumi = [
        "/celojumi/02243440-bac9-40bb-a74e-4a026ce87094.jpeg",
        "/celojumi/04a2ad00-17ec-4d3f-add7-a136503d1850.jpg",
        "/celojumi/0710157f-fcc9-4fda-ad4b-ef05049c0727.jpeg",
        "/celojumi/0a42e5d3-230a-4795-bdf0-f225f1ebe8b0.jpeg",
        "/celojumi/0b0ea9f8-a939-4d6e-9001-005514ce1c1c.jpeg",
        "/celojumi/0DB1A0E0-8DBF-4BEA-97EE-D1F6C2C949BA_1_105_c.jpeg",
        "/celojumi/179683FF-CEE6-43AA-9019-76205AFF288F.jpg",
        "/celojumi/19C700AE-6AB7-4319-8B0E-0F34FD8C66EC_4_5005_c.jpeg",
        "/celojumi/1e65f66a-0da6-4967-b140-2328f0e5086b.jpeg",
        "/celojumi/20191028_100243_Original.jpg",
        "/celojumi/2659d09a-fc83-4696-8b79-d2737ef134fb.jpeg",
        "/celojumi/2cc92750-2a33-4c8d-b348-e6ad65983198.jpeg",
        "/celojumi/39b08ecd-11a3-4029-94cb-f9bd7ea4e187.jpg",
        "/celojumi/41c9d109-2d14-450e-8e58-6785ec968dcc.jpeg",
        "/celojumi/5299CE7B-2E49-4536-8F50-C18F4180BC8C.jpeg",
        "/celojumi/55ba1f44-bfba-4e8d-94a4-c07814e15821.jpg",
        "/celojumi/5f7bd445-1e02-4d00-9c41-2630f1ca3bd4.jpeg",
        "/celojumi/6181E475-3A31-4464-8365-4EBB96B6B64E.jpeg",
        "/celojumi/664e5a16-bdaf-4213-a2c6-d924b9372ef8.jpg",
        "/celojumi/7d361159-30c2-4ae1-901a-f65c453128b5.jpg",
        "/celojumi/7dd050c3-8ba4-4bb8-b2d4-aa4358098645.jpeg",
        "/celojumi/7e86edf4-6484-476c-8d6e-7fabfb298531.jpeg",
        "/celojumi/81cec4b1-14fb-4e1b-84c9-83260c1624fc.jpeg",
        "/celojumi/82A9681C-2878-4BF9-BF50-E51ED4139273_1_105_c.jpeg",
        "/celojumi/8a093b82-4399-4a59-af6c-73cef48a0f4b.jpeg",
        "/celojumi/8c9c52cd-afde-48ad-afb6-d9bfd2a4b994.jpeg",
        "/celojumi/8dbb8c2c-8165-404c-998b-766740fa9d22.jpeg",
        "/celojumi/9563E99C-0D62-45A7-B6C4-BFE2A4707A94_1_105_c.jpeg",
        "/celojumi/9F5CF922-9359-4DF7-A8DB-B647CBAD19AF.jpeg",
        "/celojumi/A7700CF8-F648-4077-A49C-48BA159EF8FD_4_5005_c.jpeg",
        "/celojumi/ac52ee62-96ab-4092-9fb3-1e9b0547ffe7.jpg",
        "/celojumi/b002a875-c327-44ab-b65b-8cdd0f737890.jpeg",
        "/celojumi/b0732170-0716-47a7-8cc5-7c234bf0e7aa.jpeg",
        "/celojumi/BD365D7C-A54E-4497-A63C-AA6BFA94A024_4_5005_c.jpeg",
        "/celojumi/d263f32d-bb9d-437b-8ecd-88b42c4d3549.jpg",
        "/celojumi/D8B442FD-EA19-4DAB-A27D-A94F8E4CEFA3.jpeg",
        "/celojumi/e321385a-015d-41de-a339-396e643bf76e.jpeg",
        "/celojumi/e436cdb1-33a5-4df2-86ea-ce30bc95af96.jpg",
        "/celojumi/e67363ef-abc5-42a3-8c91-c3d2c62e0c93.jpeg",
        "/celojumi/FD73D524-7763-4B21-9240-95E9A3C04C48.jpeg",
        "/celojumi/IMAG0904.jpg",
        "/celojumi/IMAG1105.jpg",
        "/celojumi/IMG220.jpg",
        "/celojumi/IMG_8800.jpg",
        "/celojumi/IMG_8843.jpg",
        "/celojumi/IMG_9056.jpg",
        "/celojumi/IMG_9057.jpg",
        "/celojumi/IMG_9058.jpg"
      ];      

    return (
        <div className='koncerti'>
            <h1>CEĻOJUMI</h1>
            <p>
                &emsp; Kopīgi esam bijuši gan tuvākos, gan tālākos pasaules stūros.
                <br></br>
                &emsp; Pēdējo reizi tā bija Dānija 2024. gadā, bet saraksts ar visām apciemotajām vietām ir garš:
                <br></br>
                Meksika (2019, 2016)
                <br></br>
                Ukraina (2017)
                <br></br>
                Vācija (2017, 2004, 1997, 1996, 1994)
                <br></br>
                Baltkrievija (2015)
                <br></br>
                Slovēnija (2014)
                <br></br>
                Polija (2013)
                <br></br>
                Ungārija (2003)
                <br></br>
                Japāna (2001)
                <br></br>
                Itālija (1998)
                <br></br>
                Spānija (1997)
                <br></br>
                Somija (1995, 1994)
                <br></br>
                Sibīrija (1992)
                <br></br>
                Moldāvija (1991)
                </p>
            <SwipeGallery images={celojumi} />
        </div>
    );
};

export default Celojumi;
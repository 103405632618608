import React from 'react';
import '../styles/koncerti.css';

const Sauklis = () => {

    return (
        <div className='koncerti'>
            <h1>SAUKLIS</h1>
            <p> &emsp; "Nespēs uzbrukt zvērs šis ļaunais ruksīšiem, kas nikni kož! Ne pūkas, ne spalvas! Pie velna!💥"
                <br></br>
                &emsp; Ūsiņa saukļa pirmsākumi meklējami tālajā 1991.gadā, kad Ūsiņš ar tā brīža vadītāju Brigitu Čiksti viesojās Moldāvijā. Līdz ar to sauklis, kas vienmēr izpildīts ciešā aplītī, ar Ūsiņa dejotājiem ir jau 33 gadus!💥💜 Kas tad ir tie ruksīši? Pašu dejotāji!🕺🏼 Un, kas ir ļaunais zvērs, kurš nespēs uzbrukt nabaga ruksīšiem? Moldāvijas saule!☀️ Kāpēc? Jo nabaga latvieši ar bālo ādas krāsu nodeguši saulē un kļuvuši pavisam sarkani. Izskatījušies pēc ruksīšiem!🐷 Niknā košana, lai paliek mistērija!🫣😅 
                <br></br>
                &emsp; Šim sauklim ir arī turpinājums- meiteņu un puišu aplītis, bet tas, lai paliek aizskatuvei!🫣
            </p>
        </div>
    );
};

export default Sauklis;
import React, {useState} from 'react';
import '../styles/Programma.css';

const programma = {
  1: { "title": "Ūsiņš jāj pieguļā", "group": "ŪSIŅŠ", "info": "A. Puriņas horeogrāfija, V. Pūces mūzika" },
  2: { "title": "Ciguļi", "group": "SIENĀZĪTIS 1.-2. kl. grupa", "info": "D. Advlijones horeogrāfija, R. Kaupera mūzika un vārdi, tautas dziesma" },
  3: { "title": "Ai, tēvu zeme", "group": "ex ŪSIŅŠ", "info": "U. Žagatas horeogrāfija, R. Paula mūzika" },
  4: { "title": "Aiz ezera augsti kalni", "group": "ŪSIŅŠ", "info": "U. Žagatas horeogrāfija, R. Paula mūzika" },
  5: { "title": "Rūnu raksti", "group": "ŪSA", "info": "J. Purviņa horeogrāfija, D. Stalta muzikāla apdare" },
  6: { "title": "Apkal manu kumeliņu", "group": "ŪSIŅŠ", "info": "A. Daņiļeviča horeogrāfija, J. Vaivoda mūzika" },
  7: { "title": "Pūpoldanči", "group": "SIENĀZĪTIS 3.-4. kl. grupa", "info": "L. Liporas horeogrāfija, R. Kaupera mūzika, I. Cipes vārdi" },
  8: { "title": "Saule brida rudzu lauku", "group": "ex ŪSIŅŠ", "info": "J. Ērgļa horeogrāfija, I. Rupaines mūzika" },
  9: { "title": "Krustamātes krakovjaks", "group": "ex ŪSIŅŠ", "info": "I. Magones horeogrāfija, E. Melngaiļa mūzika R. Paula apdarē" },
  10: { "title": "Latgaliešu dancis", "group": "ŪSIŅŠ", "info": "J. Ērgļa horeogrāfija, I. Reiznieces mūzika" },
  11: { "title": "Žeperis Aulejskijs", "group": "AUSTRIŅŠ", "info": "I. Dreļa horeogrāfija, tautas mūzika D. Baltkājes apdarē" },
  12: { "title": "Zvoščiks", "group": "RĪGAVA", "info": "I. Dreļa horegrāfija, tautas mūzika E. Lipora apdarē" },
  13: { "title": "Spēlē spēlmani", "group": "ex ŪSIŅŠ", "info": "I. Magones horeogrāfija, E. Melngaiļa mūzika R. Paula apdarē" },
  14: { "title": "Baltu puķu guni kūru", "group": "ŪSIŅŠ", "info": "J. Ērgļa horeogrāfija, I. Reiznieces mūzika" },
  15: { "title": "Pēcpusdiena Piebalgā", "group": "ex ŪSIŅŠ", "info": "I. Mažānes horeogrāfija, M. Šēra mūzika" },
  16: { "title": "Dundari, dandari", "group": "SIENĀZĪTIS 7.-9. kl. grupa", "info": "A. Novika horeogrāfija, I. Gaiķes mūzika" },
  17: { "title": "Jumalo", "group": "ex ŪSIŅŠ", "info": "S. Purmales horeogrāfija, H. Staltes mūzika" },
  18: { "title": "Ak, šis priekšautiņš", "group": "ex ŪSIŅŠ", "info": "H. Sūnas horeogrāfija, D. Zaķa mūzika" },
  19: { "title": "Šķērsu dienu saule tek", "group": "ŪSIŅŠ", "info": "J. Ērgļa horeogrāfija, tautas mūzika grupas \"Iļģi\" aranžijā" },
  20: { "title": "Es kādreiz Talsos aizbraucu", "group": "RĪGAVA", "info": "S. Laurinovičas horegrāfija, tautas mūzika I. Harmsena apdarē" },
  21: { "title": "Jāņu nakti neguļat", "group": "AUSTRIŅŠ", "info": "J. Ērgļa horeogrāfija, R. Tiguļa mūzika" },
  22: { "title": "Blusa", "group": "SIENĀZĪTIS 5.-6. kl. grupa", "info": "A. Daņiļeviča horeogrāfija, tautas mūzika K. Kareles aranžējumā" },
  23: { "title": "Kurzeme", "group": "ŪSIŅŠ", "info": "H. Sūnas horeogrāfija, R. Paula mūzika" },
  24: { "title": "Pie Dzintara jūras", "group": "ex ŪSIŅŠ", "info": "A. Baumanes un O. Graša horeogrāfija, R. Paula muzikālā apdare" },
  25: { "title": "Dod man spēku, dod man laiku", "group": "ŪSIŅŠ", "info": "J. Ērgļa horeogrāfija, J. Kulakova mūzika" },
  26: { "title": "Manai dzimtenei", "group": "ex ŪSIŅŠ", "info": "A. Spuras horeogrāfija, R. Paula mūzika" },
  27: { "title": "Es mācēju danci vest", "group": "ŪSA", "info": "U. Žagatas horeogrāfija, G. Ordelovska muzikālā apdare" },
  28: { "title": "Skaista mana tēvu zeme", "group": "ŪSIŅŠ", "info": "D. Bārbalas horeogrāfija, tautas mūzika E. Kārkla apdarē" },
  29: { "title": "K. DIMANTA UN GRUPA “ZELTRAČI”", "info": "Koncerta daļa ar unikāli Ūsiņam radītām dejām un mūziku" },
  30: { "title": "LAIMA", "group": "ŪSIŅŠ", "info": "U. Eglītes horeogrāfija, K. Dimantas mūzika" },
  31: { "title": "VĒJA MĀTE", "group": "ŪSIŅŠ", "info": "U. Eglītes horeogrāfija, K. Dimantas mūzika" },
  32: { "title": "PĒRKONS", "group": "ŪSIŅŠ", "info": "U. Eglītes horeogrāfija, K. Dimantas, R. Zelča, K. Stroda, M. Uškāna mūzika" },
  33: { "title": "PĀR KALNIŅU ŪSIŅŠ JĀJ", "group": "ŪSIŅŠ", "info": "M. Pēdāja horeogrāfija, K. Dimantas mūzika" },
  34: { "title": "OZOLS", "group": "ŪSIŅŠ", "info": "M. Pēdāja horeogrāfija, K. Dimantas mūzika" },
  35: { "title": "MĀRA", "group": "ŪSIŅŠ", "info": "M. Pēdāja horeogrāfija, K. Dimantas mūzika" },
  36: { "title": "AUSEKLIS", "group": "ŪSIŅŠ", "info": "M. Pēdāja un U. Eglītes horeogrāfija, K. Dimantas mūzika" }
};



function Programma({ onBackClick }) {
  const [visibleItem, setVisibleItem] = useState(null);

  const toggleVisibility = (key) => {
    setVisibleItem(visibleItem === key ? null : key);
  };

  return (
    <div className="programma-section section">
      <h2>KONCERTA PROGRAMMA</h2>
      <ul className="programma-list">
        {Object.keys(programma).map((key) => (
          <li key={key} className={`programma-item ${visibleItem === key ? 'expanded' : ''}`} onClick={() => toggleVisibility(key)}>
            <div className="programma-title">
              <div className="top-text"> 
                <span className="programma-id">{key}.</span>
                <span>{programma[key].title}</span>
              </div>
              <svg className={`arrow ${visibleItem === key ? 'expanded' : ''}`} width="12" height="12" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6 9l6 6 6-6" stroke="#523f39" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </div>

            <div className="programma-details">
              {programma[key].group && (
                <div className="programma-group">Dejo: {programma[key].group}</div>
              )}
              {programma[key].info && (
                <div className="programma-info">{programma[key].info}</div>
              )}
            </div>
          </li>
        ))}
      </ul>
      <button onClick={onBackClick} className="back-button">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M9 6l6 6-6 6" stroke="#E9E0D9" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
      </button>
    </div>
  );
}

export default Programma;
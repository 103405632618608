import React, { useState } from 'react';
import '../styles/Mes.css';
import Modal from './Modal';
import Par from '../content/Par';
import Svetki from '../content/Svetki';
import Balles from '../content/Balles';
import Koncerti from '../content/Koncerti';
import Celojumi from '../content/Celojumi';
import Sauklis from '../content/Sauklis';
import Vesture from '../content/Vesture';
import Citi from '../content/Citi';
import Secret1 from '../content/Secret1';
import Secret2 from '../content/Secret2';
import Secret3 from '../content/Secret3';


// Import other content components similarly

function Mes({ onBackClick, setActiveModal }) {
  const [activeModal, setLocalActiveModal] = useState(null);

  const handleOpenModal = (category) => {
    setLocalActiveModal(category);
    setActiveModal(category);
  };

  const handleCloseModal = () => {
    setLocalActiveModal(null);
    setActiveModal(null);
  };

  const renderModalContent = () => {
    switch (activeModal) {
      case 'PAR':
        return <Par />;
      case 'SVĒTKI':
        return <Svetki />;
      case 'BALLES':
        return <Balles />;
      case 'KONCERTI':
        return <Koncerti />;
      case 'CEĻOJUMI':
        return <Celojumi />;
      case 'SAUKLIS':
        return <Sauklis />;
      case 'VĒSTURE':
        return <Vesture />;
      case 'CITI':
        return <Citi />;
      case 'Secret1':
        return <Secret1 />;
      case 'Secret2':
        return <Secret2 />;
      case 'Secret3':
        return <Secret3 />;
      default:
        return null;
    }
  };

  return (
    <div className="mes-section">
      <button onClick={onBackClick} className="up-arrow-button">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M6 15l6-6 6 6" stroke="#E9E0D9" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
      </button>
      <div className="interactive-logo"
        style={{
          backgroundImage: `url(${process.env.PUBLIC_URL + '/logo_bess.svg'})`,
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center'
        }}
      >
        <button className="word-button par" onClick={() => handleOpenModal('PAR')}>PAR</button>
        <button className="word-button svetki" onClick={() => handleOpenModal('SVĒTKI')}>SVĒTKI</button>
        <button className="word-button balles" onClick={() => handleOpenModal('BALLES')}></button>
        <button className="word-button koncerti" onClick={() => handleOpenModal('KONCERTI')}>KONCERTI</button>
        <button className="word-button celojumi" onClick={() => handleOpenModal('CEĻOJUMI')}>CEĻOJUMI</button>
        <button className="word-button sauklis" onClick={() => handleOpenModal('SAUKLIS')}>SAUKLIS</button>
        <button className="word-button vesture" onClick={() => handleOpenModal('VĒSTURE')}>VĒSTURE</button>
        <button className="word-button citi" onClick={() => handleOpenModal('CITI')}>CITI</button>
        <button className="word-button secret1" onClick={() => handleOpenModal('Secret1')}></button>
        <button className="word-button secret2" onClick={() => handleOpenModal('Secret2')}></button>
        <button className="word-button secret3" onClick={() => handleOpenModal('Secret3')}></button>
      </div>
      <Modal show={activeModal !== null} onClose={handleCloseModal}>
        {renderModalContent()}
      </Modal>
    </div>
  );
}

export default Mes;

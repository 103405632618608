import React from "react";

import Home from "./pages/home.jsx";

import "./styles/styles.css";

export default function App() {
  return (
    <>
     <Home />
    </>
  );
}

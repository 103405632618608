import React from 'react';
import '../styles/koncerti.css';
import SwipeGallery from '../pages/SwipeGallery';

const Svetki = () => {

    const svetki2023 = [
        "/svetki/2023/04158571-E99D-4FC5-983A-6DD071397A45_1_105_c.jpeg",
        "/svetki/2023/0D27314A-40F5-42B0-B0E9-2E98219CFD8C_1_105_c.jpeg",
        "/svetki/2023/0E1AE69F-7EAA-42A6-BFC9-19D0B1A36BBF_1_105_c.jpeg",
        "/svetki/2023/0E6FC4B5-06D0-448C-8A6B-2E80387C3222_1_105_c.jpeg",
        "/svetki/2023/0ee5a1f1-4300-4c0a-a0a8-4edb88146715.jpg",
        "/svetki/2023/104aad1b-2368-450b-98ef-78578a2101a8.jpg",
        "/svetki/2023/1171F775-9D30-4BA7-8A37-DA1EF82126A2_1_105_c.jpeg",
        "/svetki/2023/12098104-4DF2-4627-A995-6E02412EF831_1_105_c.jpeg",
        "/svetki/2023/122752c2-b5eb-40b2-97ac-15a29f0f239e.jpg",
        "/svetki/2023/13507FE9-F319-435D-A261-80C292AA0B3C_1_105_c.jpeg",
        "/svetki/2023/142795F6-BC63-4A0A-A79B-91CA1B6CDFA7_1_105_c.jpeg",
        "/svetki/2023/14BECE1D-C80B-4430-A7E7-51B788776B83_1_105_c.jpeg",
        "/svetki/2023/14F38679-F2A5-44D6-9257-DB264F7199F3_1_105_c.jpeg",
        "/svetki/2023/18077974-00BB-4738-BEA9-F3270C60AF1F_1_201_a.jpeg",
        "/svetki/2023/1A82FB58-3C77-4491-A0AD-9652468A2995_1_105_c.jpeg",
        "/svetki/2023/1b0504e3-7746-4b69-94a4-d2147dc140e3.jpg",
        "/svetki/2023/1C5D2427-D621-496E-BA05-914CF896C5A9_1_105_c.jpeg",
        "/svetki/2023/1D1B5D38-24FD-498E-BCFC-5CE6CB6AC341_1_105_c.jpeg",
        "/svetki/2023/1DC31355-708B-4A17-B532-FEE657A40FAE.jpeg",
        "/svetki/2023/20230702_111104.jpg",
        "/svetki/2023/24122a85-c7c2-4de3-8c07-46f34f3989bd.jpg",
        "/svetki/2023/252b9087-b506-45f9-9368-6fe65f3534e9.jpg",
        "/svetki/2023/26D04FF0-3F77-4190-9FF6-48A8F0B419D1_1_105_c.jpeg",
        "/svetki/2023/295A6D91-E27B-478B-AE58-4C74801FC375.jpg",
        "/svetki/2023/2AC102FB-406A-41CA-A29E-3D8A4CC6D3DD_1_105_c.jpeg",
        "/svetki/2023/2E22A805-8468-4ED5-A885-1BB4DC11F128_1_105_c.jpeg",
        "/svetki/2023/331D0959-44DF-4C72-9B88-EA983FBD43EE_1_105_c.jpeg",
        "/svetki/2023/334bb75a-78a3-4da7-b1bf-08dabb703246.jpg",
        "/svetki/2023/3435F195-4010-4F56-8E38-49960EC829A9_1_105_c.jpeg",
        "/svetki/2023/398A9892-410D-4738-91E3-D0240C890EA5_1_105_c.jpeg",
        "/svetki/2023/3a905157-d78a-4ec6-9fab-e0ad3dc11245.jpg",
        "/svetki/2023/3b99e5d3-a60d-49fd-b099-31dd6a6b32cb.jpg",
        "/svetki/2023/3C9E1122-0B8D-41BB-AAE9-A9A29D017367.jpg",
        "/svetki/2023/3DA00FB6-4D2D-4A4A-94A0-F72BEE99EC94_1_105_c.jpeg",
        "/svetki/2023/3DBE02CB-5A08-405F-82E2-147F4CC8AD5E_1_105_c.jpeg",
        "/svetki/2023/3DEA7F42-6751-44FB-B6FA-6ECAD0233FDF_1_105_c.jpeg",
        "/svetki/2023/41FB09E4-DFB3-4FE0-93A6-3299C15A7168_1_105_c.jpeg",
        "/svetki/2023/42D79AF6-BBA4-42B7-B87C-CEEA5059D80C_1_105_c.jpeg",
        "/svetki/2023/45249E58-9671-4411-A1AF-20DC1EA2AC3E_1_105_c.jpeg",
        "/svetki/2023/46786D40-5ECE-438D-8698-5C58C22386F8_1_105_c.jpeg",
        "/svetki/2023/4C7D6FAD-0FB7-4A40-8227-EE7FB14401C7_1_105_c.jpeg",
        "/svetki/2023/4CF099D4-200A-4A2A-8472-6AEEEABE1BCF.jpg",
        "/svetki/2023/4e5d9295-e504-41a6-bac1-3a8572cf42a1.jpg",
        "/svetki/2023/4e8c2fae-155f-4225-b281-4242ed4d174a.jpg",
        "/svetki/2023/56A78690-6613-4ACF-AD4A-91D4234E69E1_1_105_c.jpeg",
        "/svetki/2023/599eb5b6-5976-4dbf-bccd-5e2524dfaf26.jpg",
        "/svetki/2023/5C58042E-1BC4-4C88-9BBF-2F3F052D995E_1_105_c.jpeg",
        "/svetki/2023/5CB5A210-1A63-46C5-8073-D5A372E46757_1_105_c.jpeg",
        "/svetki/2023/5d08f1a6-bcd8-4d17-9b84-d37618d260d5.jpg",
        "/svetki/2023/6065267D-09C4-492C-8F1B-8E51CAE716D9_1_105_c.jpeg",
        "/svetki/2023/6284c7b9-c400-4e54-8c90-138cb58f14ca.jpg",
        "/svetki/2023/62ec6b94-c8b1-4b07-8e32-3fd4f9e1eec0.jpg",
        "/svetki/2023/6427F567-07F2-4880-A8C7-F2D232677603_1_105_c.jpeg",
        "/svetki/2023/6486017B-E409-4FD4-A02D-6F3E1E5B6B6E_1_105_c.jpeg",
        "/svetki/2023/679687EC-1655-47DE-B68D-AF188D02DD02.jpeg",
        "/svetki/2023/68F5BC57-AA20-461C-A4D5-C3156A0D4C53_1_105_c.jpeg",
        "/svetki/2023/6a3f1526-1d19-436f-8549-a228e795efa2.jpg",
        "/svetki/2023/6C265796-C2A5-4EFC-B55F-763EB500E60F_1_105_c.jpeg",
        "/svetki/2023/6FFB66C7-22FA-4768-8AD1-D775FDDBA6AE_1_105_c.jpeg",
        "/svetki/2023/73B7EA49-F053-4214-82EA-348161F5F52B.jpg",
        "/svetki/2023/7541A6E4-7FC4-4C7D-A97C-970A14E697EB_1_201_a.jpeg",
        "/svetki/2023/7829d51c-1c9e-4b2b-bd29-86a48dcaa1a4.jpg",
        "/svetki/2023/78bced1c-e014-4cf3-a3b6-2f00c54cecff.jpg",
        "/svetki/2023/7B96792F-B49E-4610-9B05-F1932930A155_1_105_c.jpeg",
        "/svetki/2023/7d699e2d-69ef-4a48-b94e-0d1137cd629f.jpg",
        "/svetki/2023/7D9848FE-4C26-4ECD-852C-3AEEA2C3CB2E_1_105_c.jpeg",
        "/svetki/2023/7e28792b-5e9a-4df5-b39c-cf7e3527c18a.jpg",
        "/svetki/2023/7E5BD382-3CD7-4115-B7A2-05425278EC4D_1_105_c.jpeg",
        "/svetki/2023/7FA31EA1-770C-46FF-AE1B-72F2DC2A1404_1_201_a.jpeg",
        "/svetki/2023/82AB063E-FBFC-4359-B479-338A9C106CF9_1_105_c.jpeg",
        "/svetki/2023/8547e4ea-96d2-4647-a077-7bc10e1b6a86.jpg",
        "/svetki/2023/873f6d4c-29af-45cd-8b25-05a9ce809783.jpg",
        "/svetki/2023/87c9d632-b043-4bbb-be5b-4cae9422721e.jpg",
        "/svetki/2023/8AE8B455-BE35-493E-BE43-FC94B7E6FDD0_1_105_c.jpeg",
        "/svetki/2023/8EB91375-95EB-49DC-81AC-C613C43F6892_1_105_c.jpeg",
        "/svetki/2023/8F160747-3C67-4B19-A89A-F59AC83F2CF4_1_105_c.jpeg",
        "/svetki/2023/938D15E7-CF93-4A54-8BC1-6836A85D5AAA_1_105_c.jpeg",
        "/svetki/2023/99D389DA-8E3E-4BF0-8DDE-75774883EA0F_1_105_c.jpeg",
        "/svetki/2023/9A051B3A-B48F-47F3-8307-3C78F4CFA8D9_1_105_c.jpeg",
        "/svetki/2023/9B2B8075-5509-469D-A3C3-7088F92D468D_1_105_c.jpeg",
        "/svetki/2023/9B945658-3AA1-4F3C-B356-4E205F02C083_1_105_c.jpeg",
        "/svetki/2023/9C461F38-6D33-4545-B552-F569435C4D6A_1_105_c.jpeg",
        "/svetki/2023/9D328174-2052-47A1-B0A7-95015CCE496E_1_105_c.jpeg",
        "/svetki/2023/9EACCA33-2407-4B3A-9326-7936F67B56C5_1_105_c.jpeg",
        "/svetki/2023/9f0f7780-85eb-4b37-b117-097eeeaeb9a4.jpg",
        "/svetki/2023/9FC933DB-8DFB-4BF5-ADB4-647E8869636E_1_105_c.jpeg",
        "/svetki/2023/a00a88ac-437a-43a2-9bf5-d0fd918b679d.jpg",
        "/svetki/2023/A0CE5B8E-D343-4D1E-A4FF-88EB0325DFDF.jpeg",
        "/svetki/2023/A514A93B-E5D4-4151-B928-5D528E1D6CA5_1_105_c.jpeg",
        "/svetki/2023/a54a8cc9-977f-4719-8d2d-7c6cada25204.jpg",
        "/svetki/2023/A58524E4-07CE-4153-9141-602045786285_1_105_c.jpeg",
        "/svetki/2023/ABFC58BC-EE29-4FA3-A260-815720996A6B_1_105_c.jpeg",
        "/svetki/2023/b1c7f94f-4025-4f78-9845-1609dfdf2c08.jpg",
        "/svetki/2023/B1CE8B23-71AC-4EE9-A89D-BD23CB617B79_1_105_c.jpeg",
        "/svetki/2023/B23BB232-5A77-4289-AC3D-31386BA9D0E3_1_105_c.jpeg",
        "/svetki/2023/B45861C8-9BE2-4CAF-B996-794ED76BACCA_1_105_c.jpeg",
        "/svetki/2023/C1054046-6ADF-4821-9306-56BE8E50C54C_1_105_c.jpeg",
        "/svetki/2023/C57F7EB7-44BE-49D4-9C4D-EC7728A98E83_1_105_c.jpeg",
        "/svetki/2023/C79A70C7-B294-4197-851E-270E86583C81_1_105_c.jpeg",
        "/svetki/2023/C7A32E2A-E8DE-476C-9426-DBE31D041301_1_105_c.jpeg",
        "/svetki/2023/CD55E854-9620-430E-9AF9-EF01F975B0A7_1_105_c.jpeg",
        "/svetki/2023/D08E7E46-078C-44EC-97B8-641E50F8ACEC_1_105_c.jpeg",
        "/svetki/2023/D9134991-A16A-4C86-BC27-FF54492D2DAE_1_105_c.jpeg",
        "/svetki/2023/DA179E49-4C2B-4DE4-8C28-D6072CE0B34B_1_105_c.jpeg",
        "/svetki/2023/db586968-22af-46bd-ba3f-5d1a34096424.jpg",
        "/svetki/2023/DDB149DB-B418-42FE-8941-B40BB712F48C_1_105_c.jpeg",
        "/svetki/2023/E0E3E69C-0259-4AF2-94B4-D16C2E8129C3_1_105_c.jpeg",
        "/svetki/2023/E0EA622C-7F4F-404C-92F4-A4A99C4FD21E_4_5005_c.jpeg",
        "/svetki/2023/e102c95e-72d4-4c7f-a862-93a225c88fe6.jpg",
        "/svetki/2023/E207CAFB-FBE3-4399-97B9-BE0343F7537D_1_105_c.jpeg",
        "/svetki/2023/E2D081BD-ED1D-417D-828A-9DCF744831CC_1_105_c.jpeg",
        "/svetki/2023/E3E27729-2FDC-47FD-8D8B-A3F0E10467C7_1_105_c.jpeg",
        "/svetki/2023/E6CE4319-8669-46A9-B6A3-55655E6F6C19_1_105_c.jpeg",
        "/svetki/2023/e7f2da65-df91-4636-9148-0a1a5c8b01fb.jpg",
        "/svetki/2023/E8717E59-8846-401A-B1C4-2046D6AF6926_1_105_c.jpeg",
        "/svetki/2023/E90582A7-F7FE-4455-9063-8CCFAACD7F1B_1_105_c.jpeg",
        "/svetki/2023/ED2224EF-02E7-4EF7-A863-45ABF2F7C0C3_1_105_c.jpeg",
        "/svetki/2023/EDBE6FFA-BADF-4C67-8643-5A93909AA21C_1_105_c.jpeg",
        "/svetki/2023/EE71A661-4107-41AC-83C0-F1ACB6CDD500_1_105_c.jpeg",
        "/svetki/2023/EF573AB4-9BE5-4546-AD95-FA9330AAFAB3_1_105_c.jpeg",
        "/svetki/2023/F308B85E-F213-4DDC-8485-28D59D824D08_1_105_c.jpeg",
        "/svetki/2023/F582E7AA-EAE7-41D5-A8C7-1BAECE54FDE4_1_105_c.jpeg",
        "/svetki/2023/F5908E06-FD8F-4371-8AD2-F7E012BFDD70_1_105_c.jpeg",
        "/svetki/2023/F935602A-EA64-4C76-B6B8-90ED916452B1_1_105_c.jpeg",
        "/svetki/2023/F97CA192-60F5-4B75-AD4B-1CB47B1C2A2B_1_105_c.jpeg",
        "/svetki/2023/FC947F86-DCBA-4D14-9A78-A0296CEBB306_1_105_c.jpeg",
        "/svetki/2023/IMG-20230711-WA0011.jpg",
        "/svetki/2023/IMG_0862.jpg",
        "/svetki/2023/IMG_0924.jpg",
        "/svetki/2023/IMG_0931.jpg",
        "/svetki/2023/IMG_0938.jpg",
        "/svetki/2023/IMG_2558.jpg",
        "/svetki/2023/IMG_5954.jpeg",
        "/svetki/2023/IMG_6643.jpg",
        "/svetki/2023/IMG_6656.jpg",
        "/svetki/2023/IMG_6667.jpg",
        "/svetki/2023/IMG_6671.jpg",
        "/svetki/2023/IMG_6720.jpg",
        "/svetki/2023/IMG_6761.jpg",
        "/svetki/2023/IMG_6784.jpg",
        "/svetki/2023/IMG_6793.jpg",
        "/svetki/2023/IMG_6804.jpg",
        "/svetki/2023/IMG_6843.jpg",
        "/svetki/2023/IMG_6979.jpeg",
        "/svetki/2023/IMG_7102.jpg"
      ];      


    return (
        <div className='koncerti'>
            <h1>DEJU SVĒTKOS</h1>
            <p>Mēs deju svētkos, patiesi, skaisti un atklāti</p>
            <br></br>
            <p>2023</p>
            <div className='gallery'>
                <SwipeGallery images={svetki2023} />
            </div>

        </div>
    );
};

export default Svetki;
import React from 'react';
import '../styles/koncerti.css';
import SwipeGallery from '../pages/SwipeGallery';

const Balles = () => {   

    const balles = [
        "/balles/0015ceaf-c0ae-4579-872c-ad78d2ea3bb9.jpeg",
        "/balles/056FE107-5886-4E8A-8C4A-178930F9F59A_1_105_c.jpeg",
        "/balles/0a20dcd7-572c-4967-a12b-581b6332c084.jpg",
        "/balles/0DD8A29E-BBC6-4CAF-BDFC-257209947F18_1_105_c.jpeg",
        "/balles/12DDDCC5-2AE0-4C2A-A226-2F61C8D89FAC_1_105_c.jpeg",
        "/balles/14367B3C-0FDC-41DC-9DE5-4D608C0D843B_1_105_c.jpeg",
        "/balles/20191024_010658_Original.jpg",
        "/balles/29094F5E-2789-4C5D-9287-61B1FEB4C86A_1_105_c.jpeg",
        "/balles/29838ec4-d6a6-445b-843e-7dc3d176f250.jpg",
        "/balles/358742fc-afc7-4dd3-9441-eff8fcb2e053.jpeg",
        "/balles/35F23D64-E926-4E6B-8158-19E1D6C5A030_1_105_c.jpeg",
        "/balles/3616f298-10ae-4e48-91d5-46e58acac7f3.jpeg",
        "/balles/372C3BAF-AA76-4F42-909B-E6FFFDF132EE_1_105_c.jpeg",
        "/balles/3917E03B-3662-4504-A2A3-2A6C2476DAC3.jpg",
        "/balles/39A736E8-4095-4A46-A042-AEC7C0C27371_1_105_c.jpeg",
        "/balles/39C7C9D8-6FAE-4BE8-A50F-4C8FF8C2B876_1_105_c.jpeg",
        "/balles/3A4D9143-140E-484F-BE51-E13985C1130F_4_5005_c.jpeg",
        "/balles/4146B651-B9B2-4287-B2EC-DB6FBDAB1C4A_1_105_c.jpeg",
        "/balles/425d4dbd-9cde-4d27-8570-6aeea66ca49c.jpeg",
        "/balles/4813ef04-9122-47a1-bc47-ee6139ba51d4.jpg",
        "/balles/4A21D461-8405-4B4B-B370-F1ECD372576C_1_105_c.jpeg",
        "/balles/4C4A47CA-42DA-4C4F-87BF-B35A93E7B208_1_105_c.jpeg",
        "/balles/4fa6cc56-c3a8-4e6f-a674-67e83dfddf94.jpg",
        "/balles/5d9820d0-54c0-4212-ab43-ded25fce6edf.jpeg",
        "/balles/601128BC-7B37-4D2B-8737-99FC927FA515_1_105_c.jpeg",
        "/balles/662e8f6c-fd4e-4636-a75f-c9189387b4a7.jpg",
        "/balles/66cd6d29-9cef-4d69-ab22-20508a06f121.jpg",
        "/balles/673CCBF4-9864-46A4-BD5D-2BDB8C8DA56B_1_105_c.jpeg",
        "/balles/7568F246-8AE8-427A-8CB9-51F247A1A7BF_1_105_c.jpeg",
        "/balles/75DA8355-DA78-4F0A-86EA-1FE6D456FBE2_1_105_c.jpeg",
        "/balles/7ef8af5b-cb00-43df-8347-d2a5ef09f25d.jpeg",
        "/balles/848d60cb-5b4e-40ef-ab78-87c6e74c4923.jpg",
        "/balles/8932fa6a-5988-48a1-87c9-acf7e3d5d37d.jpg",
        "/balles/894992F8-0B28-41A6-97C8-DA3262500C8E_1_105_c.jpeg",
        "/balles/90A63065-3765-4C1D-9476-D61A125ED00F_1_105_c.jpeg",
        "/balles/9cdeb8a6-9f93-4966-9aee-7153c9c3a324.jpeg",
        "/balles/ae927ef5-5bb4-4b88-966c-5cb84588c435.jpg",
        "/balles/B31F8325-9A0C-49FB-85CD-0B25E1D1DD28_1_105_c.jpeg",
        "/balles/b4c5b9d7-893b-4f4c-8d91-053fa053965c.jpeg",
        "/balles/B4FFD05F-8898-45F6-93A9-C43F38B0195A_1_105_c.jpeg",
        "/balles/c5a17eac-1b34-4bfd-a385-0e0e0f045f0c.jpg",
        "/balles/C8189951-DCA3-4E00-A994-86D4EC526BD9_1_105_c.jpeg",
        "/balles/CC0C3EC7-AC47-476E-A4F7-A337B3CD94FF_1_105_c.jpeg",
        "/balles/CCDE4FB6-8FA3-4227-B403-3089A329D828_1_105_c.jpeg",
        "/balles/D7FAB49A-662F-468D-B34D-0250C3685BDB_1_105_c.jpeg",
        "/balles/d94181c6-5fe6-48cb-afc7-c055776080af.jpg",
        "/balles/DA88821E-DCAD-4545-BCCC-47E7EED4AC33_1_105_c.jpeg",
        "/balles/df6ce4d5-b82c-4420-a1fe-2b5afd954dbf.jpg",
        "/balles/E808D970-626F-468D-9035-74FD082280A9_1_105_c.jpeg",
        "/balles/E9302FAD-F041-4799-A61F-F2D64279AE3F_1_105_c.jpeg",
        "/balles/EA7454C7-99B9-44C2-BF08-EBB549C47AF6_1_105_c.jpeg",
        "/balles/ebcf3184-0d41-40c0-9e05-1b9c77a99b63.jpg",
        "/balles/EC916AD4-BCA3-40C0-84E7-27901B1C1BDB_1_105_c.jpeg",
        "/balles/f4eb45ac-404b-46dc-87c8-c9a3a016a512.jpg",
        "/balles/FA145113-708A-46D9-9C86-2629F088CE56_1_105_c.jpeg",
        "/balles/fa3c3f9c-0e3b-46e6-a9f4-9f898f1bffc2.jpg",
        "/balles/IMG_0278.jpg",
        "/balles/IMG_1530.jpg",
        "/balles/IMG_4834.jpg",
        "/balles/IMG_5647.jpeg",
        "/balles/IMG_5713.jpeg",
        "/balles/IMG_5726.jpeg"
      ];      

    return (
        <div className='koncerti'>
            <h1>BALLĒS</h1>
            <p>Te pāris "slēptās" bildes no mūsu ballītēm</p>
            <div className='gallery'>
                <SwipeGallery images={balles} />
            </div>

        </div>
    );
};

export default Balles;
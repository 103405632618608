import React from 'react';
import '../styles/Durvis.css';

function Durvis({ side, isOpen, children }) {
  const position = side === 'left' ? '0%' : '100%';
  const transform = isOpen ? (side === 'left' ? '-140%' : '140%') : '0%';

  return (
    <div
      className={`Durvis ${side} ${isOpen ? 'open' : ''}`}
      style={{
        transform: `translateX(${transform})`,
        transformOrigin: position
      }}
    >
      {children}
    </div>
    
  );

}

export default Durvis;
